<template>
  <v-overlay
    :value="show"
  >
    <v-card
      v-click-outside="close"
      color="#EDEDED"
      width="500"
      light
    >
      <v-toolbar
        color="#333"
      >
        <div class="text-h5  white--text">
          Создание документа
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        <router-link
          v-if="isAllowedToSelect"
          tag="a"
          :to="`/registration-client/${activeDivisionId}`"
          class="text-body-1 text-decoration-underline"
          style="color:#5CB7B1;"
        >
          Зарегистрируйтесь в качестве клиента таможенного агентства или приобретите новый пакет
        </router-link>
        <div
          v-else
          class="text-body-1 mb-4"
        >
          Выберите, что Вы хотите создать
        </div>
        <v-btn
          v-if="dvsn && dvsn.customsAgentClient"
          class="mr-2 mb-2"
          to="/journal/request"
        >
          Заявку
        </v-btn>
        <v-btn
          v-if="inPurchasedServices('SD_DECLARATION')"
          class="mr-2 mb-2"
          @click="createNewDocument('statistics')"
        >
          СД
        </v-btn>
        <v-btn
          class="mr-2 mb-2"
          @click="createNewDocument('transit')"
        >
          ТД
        </v-btn>
        <v-btn
          class="mr-2 mb-2"
          @click="createNewDocument('rs')"
        >
          РС
        </v-btn>
        <v-btn
          class="mr-2 mb-2"
          @click="createNewDocument('epi')"
        >
          ЭПИ
        </v-btn>
        <v-btn
          class="mr-2 mb-2"
          @click="createNewDocument('ptd')"
        >
          ПТД
        </v-btn>
        <v-btn
          class="mr-2 mb-2"
          @click="createNewDocument('dt')"
        >
          ДТ
        </v-btn>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
import {mapGetters} from 'vuex'
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    }
  },
  computed:{
    ...mapGetters({
      user: 'auth/getUser',
      dvsn: 'auth/getSingleDivision',
      purchasedServices: 'auth/getPurchasedServices',
    }),
    isAllowedToSelect(){
      return !this.purchasedServices.length && !this.dvsn?.customsAgentClient
    },
    activeDivisionId(){
      return this.user?.user?.activeDivisionId || ""
    },
  },
  methods:{
    createNewDocument(type) {
      this.$store.dispatch(`${type}/createNewDocument`)
        .then(res => {
          const {id} = res.data
          const route = this.$router.resolve({
            path: `/${type}/${id}`
          });
          window.open(route.href ,'_blank')
          this.close()
        }).catch((err) => {
          this.$snackbar({
            text: `Ошибка: ${err.response.data}`,
            color: "red",
            timeout: "10000",
          });
        });
    },
    inPurchasedServices(service){
      return !!this.purchasedServices.find(e => e.en_name === service)
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
